import { ThemeProvider } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { createContext, useEffect, useState } from "react";
import { themeCreator } from "./schemes";
import { DEFAULT_THEME } from "src/constants";

export const ThemeContext = createContext((_themeName) => {});

export function detectThemePreference() {
  const prefersDark =
    global?.window?.matchMedia("(prefers-color-scheme: dark)")?.matches ||
    false;

  const localStorageThemeName =
    global?.window?.localStorage?.getItem("appTheme");

  if (localStorageThemeName) return localStorageThemeName;
  else if (prefersDark) return DEFAULT_THEME.DARK;
  else return DEFAULT_THEME.LIGHT;
}

const ThemeProviderWrapper = (props) => {
  const [themeName, setThemeName] = useState(DEFAULT_THEME.LIGHT);

  useEffect(() => {
    const preference = detectThemePreference();

    if (themeName !== preference) setThemeName(preference);
  }, [])

  const handleSetTheme = (themeName = '') => {
    global?.window.localStorage.setItem("appTheme", themeName);
    setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={handleSetTheme}>
        <ThemeProvider theme={themeCreator(themeName)}>
          {props.children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
