import axios from "axios";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { getViewAsCompanyValue } from "src/components/utilities";
import {
  DEFAULT_HEADERS,
  DEFAULT_SYSADMIN_HEADERS,
  PASSINGS_HEADERS,
  ROLES,
  ROLES_LEVEL,
} from "src/constants";
import { userStore } from "src/store";
import useSWR from "swr";

export function useUser({ redirectTo, redirectIfFound, restrictToRole } = {}) {
  const viewAsCompany = getViewAsCompanyValue();
  const [userStoreData, setUserStoreData] = useRecoilState(userStore);
  const router = useRouter();

  const { data, error, isLoading } = useSWR(
    "/api/auth/user",
    (url) =>
      axios
        .get(url, { params: viewAsCompany })
        .then((response) => response.data),
    {
      onSuccess: (data) => setUserStoreData(data),
      onError: (err) => {
        // token is removed in endpoint
        console.error(err);
        localStorage.removeItem("dts-should-redirect");
        localStorage.removeItem("viewAsCompany");
        router.push('/')
      },
    }
  );

  const hasUser = Boolean(data && Object.keys(data).length !== 0);
  const isSysAdminOrAbove =
    ROLES_LEVEL[data?.role || ROLES.inspector] >= ROLES_LEVEL.sysAdmin;
  const defaultHeader = isSysAdminOrAbove
    ? DEFAULT_SYSADMIN_HEADERS
    : DEFAULT_HEADERS;

  useEffect(() => {
    if (restrictToRole && data) {
      if (ROLES_LEVEL[data?.role] < ROLES_LEVEL[restrictToRole]) {
        router.push(redirectTo || "/dashboard");
        return;
      }
    }

    if (!redirectTo || isLoading || !Boolean(data)) return;

    if (redirectTo && !redirectIfFound && !hasUser) {
      localStorage.removeItem("dts-should-redirect");
      router.push(redirectTo);
    } else if (redirectIfFound && hasUser) router.push(redirectTo);
  }, [data, isLoading]);

  const headersFromCompany = useMemo(() => {
    const headers = data?.company?.settings?.passingsTableHeaders;

    if (ROLES_LEVEL[data?.role] < ROLES_LEVEL.admin && Array.isArray(headers)) {
      return headers?.filter?.(
        (header) => header !== PASSINGS_HEADERS.CORRECTED
      );
    }

    if (Array.isArray(headers)) return headers;
    else return defaultHeader;
  }, [data]);

  if (error) return {};
  return {
    ...data,
    companySettings: {
      passingsTableHeaders: headersFromCompany,
    },
    hasUser: hasUser,
    roleLevel: ROLES_LEVEL[data?.role],
  };
}
