import { SettingsRounded } from "@mui/icons-material";
import {
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { i18n, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { THEMES } from "src/constants";
import { ThemeContext } from "src/theme/ThemeProvider";
import useSWR from "swr";
import { DialogWrapper, Transition } from "./overlayStyles";

export default function MoreSettings({ isSysAdmin }) {
  const { push, pathname, query } = useRouter();
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const setAppTheme = useContext(ThemeContext);
  const router = useRouter();

  const { data } = useSWR(
    "/api/company/basic",
    (url) =>
      axios.get(url).then((response) => {
        return response.data;
      }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
    }
  );

  const [selectedTheme, setSelectedTheme] = useState(
    global?.window?.localStorage?.getItem("appTheme") || THEMES.DEFAULT_THEME
  );

  const company = Number(global.localStorage?.getItem?.("viewAsCompany"));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = ({ target }) => {
    push({ pathname, query }, undefined, { locale: target.value });
    global.localStorage.setItem("i18nextLng", target.value);
    i18n.changeLanguage(target.value);
  };

  return (
    <>
      <Tooltip arrow title="">
        <IconButton color="primary" onClick={handleOpen}>
          <SettingsRounded fontSize="medium" />
        </IconButton>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle>{t("settings.title")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel>{t("settings.theme")} </InputLabel>
            <Select
              label="theme"
              fullWidth
              value={selectedTheme}
              onChange={(event) => {
                setSelectedTheme(event.target.value);
                setAppTheme(event.target.value);
              }}
            >
              {Object.values(THEMES).map((themeName) => (
                <MenuItem key={themeName} value={themeName}>
                  {themeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel>{t("settings.language")} </InputLabel>
            <Select
              label="theme"
              fullWidth
              value={i18n.language}
              onChange={handleChangeLanguage}
            >
              {["no", "en"].map((locale) => (
                <MenuItem key={locale} value={locale}>
                  {t(`settings.${locale}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isSysAdmin && (
            <>
              <Divider sx={{ mt: 2 }}>
                {t("settings.systemAdministratorSettings")}
              </Divider>
              <FormControl fullWidth>
                <InputLabel>{t("settings.company")}</InputLabel>
                <Select
                  label="theme"
                  fullWidth
                  value={company || ""}
                  onChange={(event) => {
                    global.localStorage.setItem(
                      "viewAsCompany",
                      event.target.value
                    );
                    router.reload();
                  }}
                >
                  <MenuItem value="">
                    <em>{t("none")}</em>
                  </MenuItem>
                  {data?.map(({ id, name }) => (
                    <MenuItem key={`company-select-id-${id}`} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{t("settings.companyHint")}</FormHelperText>
              </FormControl>
            </>
          )}
        </DialogContent>
      </DialogWrapper>
    </>
  );
}
